import { graphql } from "gatsby"
import React, { useMemo } from "react"
import { Table } from "react-bootstrap"
import Layout from "../../components/Layout"
import { FaLinkedin } from "react-icons/fa"

const MTechStudents = props => {
  let count = 1
  let GetStudentsByYear = useMemo(() => {
    let students = { MT21: [], MT20: [], MT19: [] }
    props.data["allMtechUpdatedCsv"]["nodes"].forEach(student => {
      if (student["RollNo"].includes("MT21")) students["MT21"].push(student)
      else if (student["RollNo"].includes("MT20"))
        students["MT20"].push(student)
      else if (student["RollNo"].includes("MT19"))
        students["MT19"].push(student)
    })
    return students
  }, [props.data])

  let students = GetStudentsByYear
  students["MT21"].reverse()
  students["MT20"].reverse()
  students["MT19"].reverse()

  let urlregx = new RegExp("^(http[s]?:\\/\\/(www\\.)?linkedin.com\\/in\\/.+)$")
  return (
    <Layout mainClass="students" title="Students">
      <h3 className="section-heading">M.Tech. Students Graduating in 2023</h3>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th style={{ width: "10%" }}>Roll. No.</th>
            <th style={{ width: "10%" }}>Name</th>
            <th style={{ width: "15%" }}>Graduating Specialization</th>
            <th style={{ width: "17%" }}>Thesis Title</th>
            <th style={{ width: "5%" }}>LinkedIn</th>
          </tr>
        </thead>
        <tbody>
          {students["MT21"].map(x => (
            <tr>
              <td>{x["RollNo"]}</td>
              <td>{x["Name"]}</td>
              <td>{x["GraduatingSpecialization"]}</td>
              <td>
                {x["Thesis_Title"].length > 0 && x["Thesis_Title"][0] != "#" ? (
                  <a href={x["Thesis_Link"]}>{x["Thesis_Title"]}</a>
                ) : (
                  "Not Applicable"
                )}
              </td>
              {urlregx.test(x["LinkedIn"]) ? (
                <td style={{ textAlign: "center" }}>
                  <a href={x["LinkedIn"]}>
                    <FaLinkedin style={{ fontSize: "1.5rem" }} />
                  </a>
                </td>
              ) : (
                <td style={{ textAlign: "center" }}>Not Available</td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>

      <h3 className="section-heading">M.Tech. Students Graduating in 2022</h3>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th style={{ width: "10%" }}>Roll. No.</th>
            <th style={{ width: "10%" }}>Name</th>
            <th style={{ width: "15%" }}>Graduating Specialization</th>
            <th style={{ width: "17%" }}>Thesis Title</th>
            <th style={{ width: "5%" }}>LinkedIn</th>
          </tr>
        </thead>
        <tbody>
          {students["MT20"].map(x => (
            <tr>
              <td>{x["RollNo"]}</td>
              <td>{x["Name"]}</td>
              <td>{x["GraduatingSpecialization"]}</td>
              <td>
                {x["Thesis_Title"].length > 0 && x["Thesis_Title"][0] != "#" ? (
                  <a href={x["Thesis_Link"]}>{x["Thesis_Title"]}</a>
                ) : (
                  "Not Applicable"
                )}
              </td>

              {urlregx.test(x["LinkedIn"]) ? (
                <td style={{ textAlign: "center" }}>
                  <a href={x["LinkedIn"]}>
                    <FaLinkedin style={{ fontSize: "1.5rem" }} />
                  </a>
                </td>
              ) : (
                <td style={{ textAlign: "center" }}>Not Available</td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>

      <h3 className="section-heading">M.Tech. Students Graduating in 2021</h3>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th style={{ width: "10%" }}>Roll. No.</th>
            <th style={{ width: "10%" }}>Name</th>
            <th style={{ width: "15%" }}>Graduating Specialization</th>
            <th style={{ width: "17%" }}>Thesis Title</th>
            <th style={{ width: "5%" }}>LinkedIn</th>
          </tr>
        </thead>
        <tbody>
          {students["MT19"].map(x => (
            <tr>
              <td>{x["RollNo"]}</td>
              <td>{x["Name"]}</td>
              <td>
                {x["GraduatingSpecialization"] == "Not applicable"
                  ? "General"
                  : x["GraduatingSpecialization"]}
              </td>
              <td>
                {x["Thesis_Title"].length > 0 && x["Thesis_Title"][0] != "#" ? (
                  <a href={x["Thesis_Link"]}>{x["Thesis_Title"]}</a>
                ) : (
                  "Not Applicable"
                )}
              </td>
              {/* justify icon center */}
              {urlregx.test(x["LinkedIn"]) ? (
                <td style={{ textAlign: "center" }}>
                  <a href={x["LinkedIn"]}>
                    <FaLinkedin style={{ fontSize: "1.5rem" }} />
                  </a>
                </td>
              ) : (
                <td style={{ textAlign: "center" }}>Not Available</td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    </Layout>
  )
}

export default MTechStudents
export const query = graphql`
  {
    allMtechUpdatedCsv(sort: { fields: RollNo, order: DESC }) {
      nodes {
        LinkedIn
        Name
        Thesis_Link
        GraduatingSpecialization
        Thesis_Title
        RollNo
      }
    }
  }
`
